<template>
	<v-container>
		<v-row>
      <v-col cols="12">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="3" align-self="center">
            <v-text-field
              v-model="hours"
              class="postTime"
              :rules="hourRules"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="1" align-self="center">
            <span class="delimiter">:</span>
          </v-col>
          <v-col cols="3" align-self="center">
            <v-text-field
              v-model="minutes"
              :rules="minuteRules"
              class="postTime"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
			<v-col cols="2">
				<v-btn-toggle v-model="hours"  color="deep-purple accent-3" borderless>
						<v-btn v-for="h in ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']"
									:key="h"
									:value="h"
									text
						>
							{{h}}
						</v-btn>
				</v-btn-toggle>
			</v-col>
			<v-col cols="2">
				<v-btn-toggle v-model="minutes" color="deep-purple accent-3" borderless>
					<v-btn
							v-for="m in ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']"
							:value="m"
							:key="m"
							text
					>
						{{m}}
					</v-btn>
				</v-btn-toggle>
			</v-col>
			<v-col cols="12">
				<v-checkbox v-model="disableAlert" label="Без уведомления"></v-checkbox>
			</v-col>
			<v-col cols="12">
				<v-btn color="info" @click="now()">Сейчас</v-btn>
			</v-col>
			<v-col cols="12">
				Время публикации: <strong>{{showTime}}</strong>
<!--				<p v-if="product.hasOwnProperty('errors')"><v-btn color="primary" :disabled="product.errors.length>0 || hours===''" @click="publish()">Запостить</v-btn></p>-->
				<p v-if="Object.prototype.hasOwnProperty.call(product, 'errors')">
          <v-btn color="primary" :disabled="product.errors.length>0 || hours===''" @click="publish2()">Запостить</v-btn>
        </p>
        <v-row>
          <v-col>
            <div v-if="product.hasOwnProperty('errors')">
              <v-btn color="success" :disabled="product.errors.length>0" @click="sendPersonal()">Отправить в личку</v-btn>
            </div>
          </v-col>
          <v-col>
            <v-select
              v-model="manager_id"
              :items="managers"
              item-value="id"
              item-text="name"
              outlined
              dense
            >
            </v-select>
          </v-col>
        </v-row>
				<div v-if="product.errors.length>0">
					Нельзя запостить:
          <ul>
            <li v-for="(error, i) in product.errors" :key="i">
              {{error.text}}
            </li>
          </ul>
				</div>
			</v-col>
			<v-dialog
					v-model="dialog"
					transition="dialog-bottom-transition"
					max-width="600"
			>
				<v-card>
					<v-card-text class="justify-center">
<!--						<v-progress-linear-->
<!--								v-model="progress"-->
<!--								:buffer-value="100"-->
<!--						></v-progress-linear>-->
						<v-progress-linear
								v-if="publishInProgress"
								indeterminate
								height="20"
								color="green"
						></v-progress-linear>
						<p>{{currentAction}}</p>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-row>
    <loader :dialog.sync="loaderDialog" :text="currentAction" :action-finished="postingFinished"></loader>
	</v-container>

</template>

<script>
// import _ from 'lodash'
import moment from 'moment'
import QueryString from 'qs'
import _ from 'lodash'
import httpClient from '../../services/http.service'
import Loader from '@/components/common/Loader'

export default {
  name: 'Publisher',
  components: {Loader},
  data(){
    return{
      totalChannels: 0, //тут общее кол-во каналов в которые надо постить
      publishedChannels: 0, //тут кол-во готовых каналов в которые запостили
      dialog: false,
      loaderDialog: false,
      progress: 10,
      currentAction: '',
      publishAt: '',
      hours: '',
      minutes: '',
      link: '',
      disableAlert: true,
      publishDisabled: true,
      postPid: 0,
      publishInProgress: true,
      manager_id: 0,
      postingFinished: false,
      hourRules: [
        v => v >= new Date().getHours() || 'Max 2 characters',
        v => v<=23 || 'too much'],
      minuteRules: [v => {
        if (this.hours <= new Date().getHours()){
          if (v <= new Date().getMinutes()){
            console.log('less than now!')
            return false
          }
          return true
        }
        return true
      },
      v=> v <= 59 || 'too much']
    }
  },
  created() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.manager_id = String(user.id)
  },
  props:{
    product: Object,
    pid: {type: Number, default: 0}
  },
  watch: {
    hours(){
      this.$store.dispatch('needSaveResultPost', true)
    },
    disableAlert(v) {
      this.$emit('update:disableAlert', v)
    },
    link(v) {
      this.$emit('update:finalLink', v)
    },
    publishedChannels(v) {
      if (v === this.totalChannels) {
        this.$store.dispatch('needReloadScheduleEvents', true)
        this.progress = 100
        this.dialog = false
      }
    },
  },
  computed:{
    managers(){
      return this.$store.state.users
    },
    selectedTime(){
      return moment().hour(this.hours).minute(this.minutes).second(0).format('X')
    },
    showTime(){
      return moment().hour(this.hours).minute(this.minutes).format('HH:mm')
    }
  },
  methods:{
    async getPid(){
      const pid = await httpClient.get('posts/pid')
      // httpClient.get('posts/pid').then(({data})=>{
      // 	this.postPid = data
      // 	this.$emit('update:pid', data)
      // 	console.log('pid prop: ', this.pid)
      // 	console.log('pid product: ', this.product.pid)
      // 	return data
      // })
      console.log('pid из getPid: ', pid.data)
      return pid.data
    },
    publish2(){
      this.postingFinished = false
      this.$store.dispatch('needUpdateResultPost', true)
      this.$store.dispatch('needSaveResultPost', true)
      this.$store.dispatch('needSaveProduct', true)
      if (this.product.links.length>0){
        if (_.isEmpty(this.product.links[0].link)){
          return false
        }
      }
      let offerId = 0
      if (this.product.partnerId === 1) {offerId = this.product.partnerId}
      else if (this.product.partnerId === 2) {offerId = this.product.shop.epn_id}
      else if (this.product.partnerId === 3) {offerId = this.product.shop.admitad_id}

      const info = {
        tg_method: this.product.postMethod,
        product_id: this.product.id,
        channels: this.product.channel,
        text: this.product.finalText,
        links: this.product.links,
        partner_id: this.product.partnerId,
        offer_id: offerId,
        shop_id: this.product.shop.id,
        disable_notification: this.product.disableAlert,
        post_type: 'single',
        url: this.product.aliLink,
        button_text: this.product.button_text,
        erid: this.product.erid,
        aep_creative_id: this.product.aepCreativeId,
      }

      if (info.text === ''){
        this.$toast('Нельзя публиковать пост с пустым текстом!')
        return false
      }
      console.log('info for publish: ', info)
      this.loaderDialog = true
      this.currentAction = 'Сохраняем пост...'
      httpClient.post(`posts/publish?planned_at=${this.selectedTime}`, info).then(({data})=>{
        this.postingFinished = true
        this.publishInProgress = false
        if (data.success){
          this.currentAction = 'Йоху, пост размещён удачно! Хорошая работа!'
          return true
        }else{
          this.currentAction = `Не удалось сохранить пост :( ${data.errors}`
          return false
        }
      })
      return true //чтоб Eslint не ругался, заебал
    },
    sendPersonal(){
      this.$store.dispatch('needUpdateResultPost', true)
      setTimeout(() => {
        httpClient.post(`posts/send-personal?user_id=${this.manager_id}&method=${this.product.postMethod}&product_id=${this.product.id}
      &link=${this.product.aliLink}`, QueryString.stringify({text:this.product.finalText, links: this.product.links}))
      }, 1000)
    },
    hourDisabled(h){
      h++
      var currentD = new Date()
      var selected = new Date()
      selected.setHours(h, 0, 0)
      if(selected >= currentD) {
        return false
      }
      return true
    },
    minuteDisabled(m){
      var currentD = new Date()
      var selected = new Date()
      selected.setHours(this.hours, m, 0)
      if(selected >= currentD) {
        return false
      }
      return true
    },
    now(){
      this.minutes = new Date().getMinutes()
      this.hours = new Date().getHours()
    },
  }
}
</script>

<style lang="css" scoped>
	.v-btn-toggle {
		flex-direction: column;
	}
  /deep/ .postTime input {
    font-size: 2em !important;
    text-align: center;
  }
  .delimiter{
    text-align: center;
    font-size:2.5em;
    margin-left: 40%;
  }
</style>
